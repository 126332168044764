import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const LearnCard = (props) => {

 return(
<div className="my-5 bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  rounded-3xl p-4">
                                            <img src={props.item.image} className="rounded-3xl shadow-2xl drop-shadow-2xl" style={{width:'100%'}}></img>
                                            <h2 className="flex-auto text-5xl text-center py-10 text-bold font-bold">{props.item.title}</h2>

                                            <div className="row" style={{width: '100%', margin: 0, paddingTop: '15px'}}>
                                            <div className="lg:col-6" style={{paddingLeft: '0px !important'}}>
                                                                    <Link to={'/player_en/' + btoa(props.uid)}>
                                                                        <div
                                                                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-4 py-4 text-lg font-bold  mb-3 cursor-pointer"
                                                                            style={{
                                                                                backgroundColor: '#f50057',
                                                                                color: 'white',
                                                                                wordWrap: 'break-word'
                                                                            }}>
                                                                            <div className="row" style={{width: '100%'}}>
                                                                                <div className="col-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         className="h-8 w-8" viewBox="0 0 20 20"
                                                                                         fill="currentColor">
                                                                                        <path fillRule="evenodd"
                                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                                              clipRule="evenodd"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="col-9 font-bold text-right pr-0">
                                                                                    <p className="text-right">Empezar</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                                <div className="lg:col-6" style={{paddingLeft: '0px !important'}}>
                                                                <a href={"/learn/" + btoa(props.uid)}>
                                                                        <div
                                                                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-4 py-4 text-lg font-bold  mb-3 cursor-pointer"
                                                                            style={{
                                                                                backgroundColor: 'white',
                                                                                color: 'black',
                                                                                wordWrap: 'break-word'
                                                                            }}>
                                                                            <div className="row" style={{width: '100%'}}>
                                                                                <div className="col-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         className="h-8 w-8" viewBox="0 0 20 20"
                                                                                         fill="currentColor">
                                                                                        <path
                                                                                            d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="col-9 font-bold text-right pr-0">
                                                                                    <p className="text-right">Leer más</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                            </div>
                                        </div>
                                        );

                                    }
                                    export default LearnCard;