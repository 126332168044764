import React from 'react';
const Button = (props) => {

return (
                                <div
                                    className={props.customClass + ' flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer'}
                                    style={{backgroundColor: props.backgroundColor, color: props.color, wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        {props.children}
                                    </div>
                                </div>
                            );

                        }
                        export default Button;