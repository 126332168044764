import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './Dashboard.module.css';
import MovieCard from '../Components/MovieCard';
import Button from '../Components/Button';
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import NewsCard from '../Components/NewsCard';
import RSSParser from 'rss-parser';
import Speech from "speak-tts";
import * as firebaseui from 'firebaseui'
const speech = new Speech();
const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            var user = authResult.user;
            var credential = authResult.credential;
            var isNewUser = authResult.additionalUserInfo.isNewUser;
            var providerId = authResult.additionalUserInfo.providerId;
            var operationType = authResult.operationType;
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
        },
        signInFailure: function(error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            // return handleUIError(error);
        },

    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow:'popup',
    signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // Other config options...
}


function News(props) {

  const parser = new RSSParser();

  const [blogs,setBlogs]=useState([]);

  const [actual, setActual] = useState('Últimas noticias');
  const [actualCat, setActualCat] = useState(null);

  const setCategory = (category) => {
    if(category != '') {    
        switch(category) {
            case "general":
              setActual('Últimas Noticias')
              break;
            case "business":
              setActual('Negocios')
              break;
            case "entertainment":
              setActual('Entretenimiento')
              break;
            case "health":
              setActual('Salud')
              break;    
            case "science":
              setActual('Ciencia')
              break; 
            case "sports":
              setActual('Deportes')
              break;           
            case "technology":
              setActual('Tecnología')
              break;
            default:
              setActual('U')
          }

          setActualCat(category);

          getData(category);
          
    }
    console.log(category)
  }

  const getData = (category = 'general') => {

    if(category !== actualCat && actualCat !== '') {

    var keys = ["2631ac6605144b12866e54a6cc0e3515","4f698a71657f48979ae0c696665c379e","3d74a232150043288983c12b52d972d0"];
    var actualKey = keys[Math.floor(Math.random() * keys.length)];
 
    fetch("https://abue-news.herokuapp.com/index.php?v2/top-headlines?country=AR&apiKey=" + actualKey + "&pageSize=90&category=" + category)
    .then(res => res.json())
    .then(
      (result) => {
        setBlogs(result.articles)
      },
      (error) => {
        speech.speak({
            text: 'Ha ocurrido un error, por favor recargue la pagina',
        })

        alert('Ha ocurrido un error, por favor, recargue la pagina')
      }
    )
  }

}
  useEffect(() => {
    getData()
  }, [])

        return(
            <div>
                {props.loading?<p>Loading..</p>:
                    (!props.loggedin?
                            <React.Fragment>
                                <p>Please sign in to see this page.</p>
                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className={classes.emailbox}/>
                            </React.Fragment>:
                            <main>
                                    <div className="p-5 mt-5 w-full shadow-lg" style={{height:'80px', background: 'black'}}>
                                        <h2 className="text-3xl float-right text-left text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Noticias</h2>
                                    
                                    <Link to="/">
                                        <button class="float-left inline-flex items-center h-10 px-5 py-5 text-black transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-gray-300">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
</svg>
  <span className="text-2xl bold font-bold">VOLVER</span>
</button>
</Link>
                                    </div>
                                <div className="container mt-10 p-0">

                                <div class="flex flex-wrap -mx-2 overflow-hidden">

                                    <div class="my-2 px-2 w-4/12 hidden lg:block overflow-hidden">
                                        <div className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  rounded-3xl p-4">
                                                
                                                <a onClick={() => setCategory('general')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                            viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd"
                                                                d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                                                                clipRule="evenodd"/>
                                                        </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Últimas noticias</p>
                                                    </div>
                                                </Button>
                                                </a>

                                                <a onClick={() => setCategory('business')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                                        <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
                                                    </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Negocios</p>
                                                    </div>
                                                </Button>
                                                    
                                                </a>
                                               

                                                <a onClick={() => setCategory('entertainment')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                                    </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Entretenimiento</p>
                                                    </div>
                                                </Button>
                                                </a>

                                                <a onClick={() => setCategory('health')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                    </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Salud</p>
                                                    </div>
                                                </Button>
                                                </a>

                                                <a onClick={() => setCategory('sports')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Deportes</p>
                                                    </div>
                                                </Button>
                                                </a>
                                                
                                                <a onClick={() => setCategory('technology')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                                                    </svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Tecnología</p>
                                                    </div>
                                                </Button>
                                                </a>
                                                
                                                <a onClick={() => setCategory('science')}>
                                                <Button backgroundColor="black" color="white">
                                                    <div className="col-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
</svg>
                                                    </div>
                                                    <div className="col-9 font-bold text-right pr-0">
                                                        <p className="text-right">Ciencia</p>
                                                    </div>
                                                </Button>
                                                </a>

                                        </div>
                                    </div>

                                    <div class="my-2 px-2 w-full lg:w-8/12 overflow-hidden">
                                        <h2 className="text-white text-left text-4xl">Estas leyendo: <b>{actual}</b></h2>
                                        {blogs.map(function(x) {
                                            return <NewsCard key={x.url} title={x.title} image={x.urlToImage} text={x.description} creator={x.author} url={x.url}></NewsCard>
                                        })}
                                    
                                    </div>

                                </div>

                            
                                </div>
                                
                            </main>
                    )}
            </div>

        );
    
}
export default News;