import React, { useEffect, useState } from 'react';
import Speech from "speak-tts";

const speech = new Speech();

const NewsCard = (props) => {
    const [speechEnabled, setSpeechEnabled] = useState(false);
    
    function speak() {
        speech.speak({
            text: props.title + '.' + props.text,
        }).then(() => {
            console.log("Success !")
        }).catch(e => {
            console.error("An error occurred :", e)
        })
    }

useEffect(() => {
    if(speech.hasBrowserSupport()) { // returns a boolean
      
        speech.init({
            'volume': 1,
             'lang': 'es-US',
             'rate': 1,
             'pitch': 1,
             'voice':'Google español de Estados Unidos',
             'splitSentences': true,
        }).then((data) => {
            setSpeechEnabled(true);
        }).catch(e => {
            console.error("An error occured while initializing Audio Support : ", e)
        })
    }
}, []);

return (
<div className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  w-full rounded-3xl p-4 my-5">

                                                {props.image != '' &&
                                                    <div className="w-full mb-5">
                                                        <img src={props.image} alt="Abue" className="w-full rounded-2xl"/>
                                                    </div>
                                                }
                                                <div className="flex-none lg:flex">
                                                    <div className="flex-auto lg:ml-3 justify-evenly py-2">
                                                        <div className="flex flex-wrap ">
                                                            <h2 className="flex-auto text-3xl lg:text-5xl text-left text-bold font-bold" style={{paddingLeft: '15px', paddingRight: '15px'}}>{props.title}</h2>
                                                        </div>

                                                        {!props.noHr &&
                                                        <div className="flex p-4 pb-2 mt-3 border-t border-gray-200 "/>
                                                        }

                                                        <div className="block space-x-3 text-sm font-medium" style={{width: '100%'}}>
                                                            <p className="text-xl text-left mb-5" style={{paddingLeft: '15px', paddingRight: '15px'}}>{props.text}</p>
                                                            
                                                            {props.creator != '' ??
                                                                <p className="text-white text-left text-lg">Autor: <b>{props.creator}</b></p>
                                                            }
                                                            <div className="row" style={{width: '100%', margin: 0, paddingTop: '15px'}}>
                                                                <div className="lg:col-6" style={{paddingLeft: '0px !important'}}>
                                                                    <a target="_blank" href={props.url}>
                                                                        <div
                                                                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-4 py-4 text-lg font-bold  mb-3 cursor-pointer"
                                                                            style={{
                                                                                backgroundColor: '#f50057',
                                                                                color: 'white',
                                                                                wordWrap: 'break-word'
                                                                            }}>
                                                                            <div className="row" style={{width: '100%'}}>
                                                                                <div className="col-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         className="h-8 w-8" viewBox="0 0 20 20"
                                                                                         fill="currentColor">
                                                                                        <path fillRule="evenodd"
                                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                                              clipRule="evenodd"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="col-9 font-bold text-right pr-0">
                                                                                    <p className="text-right">Leer más</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="lg:col-6" style={{paddingRight: '0px !important'}}>
                                                                    
                                                                {!props.noHr && speechEnabled &&
                                                                        <div
                                                                            onClick={speak}
                                                                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-4 py-4 text-lg font-bold  mb-3 cursor-pointer"
                                                                            style={{
                                                                                backgroundColor: 'white',
                                                                                color: 'black',
                                                                                wordWrap: 'break-word'
                                                                            }}>
                                                                            <div className="row" style={{width: '100%'}}>
                                                                                <div className="col-3">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
</svg>
                                                                                </div>
                                                                                <div className="col-9 font-bold text-right pr-0">
                                                                                    <p className="text-right">Escuchar</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
);

}
export default NewsCard;