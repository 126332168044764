import React from 'react';
import ReactDOM from 'react-dom';
import Dailymotion from 'react-dailymotion';
import {Link} from 'react-router-dom';
const qualities = [
    'auto',
    '240',
    '380',
    '480',
    '720',
    '1080',
    '1440',
    '2160'
];

export default class LearnPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoIndex: 0,
            quality: 'auto',
            volume: 1,
            paused: false,
            enabled: false,
            video_id: '',
            fullscreen: false,
            videoData: {}
        };

        this.handlePause = this
            .handlePause
            .bind(this);
        this.handlePlayerPause = this
            .handlePlayerPause
            .bind(this);
        this.handlePlayerPlay = this
            .handlePlayerPlay
            .bind(this);
        this.handleVolume = this
            .handleVolume
            .bind(this);
        this.handleQuality = this
            .handleQuality
            .bind(this);

        this.openFullscreen = this
            .openFullscreen
            .bind(this);
    }

    selectVideo(index) {
        this.setState({videoIndex: index});
    }

    componentWillMount() {

        let insular = '';
        let type = 'none';

        if (this.props.props.match.params.video_id) {
            insular = this.props.props.match.params.video_id;
            type = 'videos'
        } else if (this.props.props.match.params.course_id) {
            insular = this.props.props.match.params.course_id;
            type = 'learn';
        } else {
            alert('Error');
        }

        const response = this
            .props
            .db
            .collection(type)
            .doc(atob(insular));

        response
            .get()
            .then((doc) => {
                if (!doc.exists) {
                    alert('No existente');
                }

                this.setState({
                    video_id: doc
                        .data()
                        .video_id,
                    videoData: doc.data(),
                    enabled: true
                })

            })
            .catch((error) => {
                alert('Error');
            });

    }

    handlePause() {
        this.setState({
            paused: !this.state.paused
        });
    }

    handlePlayerPause() {
        this.setState({paused: true});
    }

    handlePlayerPlay() {
        this.setState({paused: false});
    }

    handleVolume() {

        if (this.state.volume == 1) {
            this.setState({volume: 0});
        }

        if (this.state.volume == 0) {
            this.setState({volume: 1});
        }

    }

    handleQuality(event) {
        this.setState({
            quality: qualities[event.target.selectedIndex]
        });
    }

    openFullscreen() {
        let elem = document.body;

        this.setState({
            fullscreen: !this.state.fullscreen
        });
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {/* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {/* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            document.exitFullscreen();
        }
    }

    render() {
        const {videoIndex, paused, volume, quality} = this.state;

        const video = 'k6B55fkTVsjB5Zx2Ryf';
        return (
            <main>
                <div
                    className="p-5 mt-5 w-full shadow-lg"
                    style={{
                        height: '80px',
                        background: '#f50057'
                    }}>
                    <h2
                        className="text-3xl float-right text-left text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Aprender</h2>

                    <Link to="/">
                        <button
                            class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 mr-5"
                                viewBox="0 0 20 20"
                                fill="currentColor">
                                <path
                                    fillRule="evenodd"
                                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                    clipRule="evenodd"/>
                            </svg>
                            <span className="text-2xl bold font-bold">VOLVER</span>
                        </button>
                    </Link>
                </div>
                <div
                    className="container p-0"
                    style={{
                        background: 'rgb(17,24,39)'
                    }}>

            
                    <h1 className="text-center lg:text-right block lg:hidden text-5xl my-6 text-white font-bold">{this.state.videoData.title}</h1>


                    <div class="flex flex-wrap -mx-1 my-auto overflow-hidden xl:-mx-2">

                        <div
                            class="my-1 px-1 overflow-hidden w-full lg:w-4/6 xl:my-2 xl:px-2 xl:w-4/6">

                            <div
                                className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white mb-5 rounded-3xl p-0">

                                <Dailymotion
                                    video={this.state.video_id}
                                    width={'100%'}
                                    className={'rounded-3xl'}
                                    height={'500px'}
                                    showEndScreen={false}
                                    autoplay={true}
                                    theme="dark"
                                    sharing={false}
                                    uiShowStartScreenInfo={false}
                                    controls={false}
                                    volume={this.state.volume}
                                    quality={'720'}
                                    showQueue={false}
                                    autoplayQueue={false}
                                    onQualitiesAvailable={(q) => console.log(q)}
                                    paused={paused}
                                    onPause={this.handlePlayerPause}
                                    onPlay={this.handlePlayerPlay}/>
                            </div>

                           

                        </div>
                        <div
                            class="my-1 px-1 w-full overflow-hidden md:w-2/6 lg:w-2/6 xl:my-2 xl:px-2 xl:w-2/6">
<h1 className="text-center hidden lg:block lg:text-right text-5xl my-6 text-white font-bold">{this.state.videoData.title}</h1>

                            <div
                                onClick={this.handlePause}
                                className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-2xl w-100  px-7 py-7 text-3xl lg:text-4xl  mb-3 cursor-pointer"
                                style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    wordWrap: 'break-word'
                                }}>
                                <div
                                    className="row"
                                    style={{
                                        width: '100%'
                                    }}>
                                    <div className="col-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-8 w-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </div>
                                    <div
                                        className="col-9 font-bold text-right pr-0"
                                        style={{
                                            padding: 0
                                        }}>
                                        <p className="text-right p-0">
                                            {
                                                paused == true
                                                    ? <a>REPRODUCIR</a>
                                                    : <a>PAUSAR</a>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                id="fs"
                                onClick={() => this.openFullscreen()}
                                className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-2xl w-100  px-7 py-7 text-3xl lg:text-4xl  mb-3 cursor-pointer"
                                style={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    wordWrap: 'break-word'
                                }}>
                                <div
                                    className="row"
                                    style={{
                                        width: '100%'
                                    }}>
                                    <div className="col-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-8 w-8"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"/>
                                        </svg>
                                    </div>
                                    <div
                                        className="col-9 font-bold text-right pr-0"
                                        style={{
                                            padding: 0
                                        }}>
                                        <p className="text-right p-0 text-black">

                                            {
                                                this.state.fullscreen
                                                    ? 'PANTALLA NORMAL'
                                                    : 'PANTALLA COMPLETA'
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
        );
    }
}