import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import './VideoJS.module.css';

export default class VideoJS extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currently: 0
        };

        this.handleOnTimeUpdate = this.handleOnTimeUpdate.bind(this);
        this.setTimeManual = this.setTimeManual.bind(this);
        this.setTime = this.setTime.bind(this);
    }

    componentDidMount() {
        this.player = videojs(
            this.videoNode,
            this.props.options,
            function onPlayerReady() {
                console.log("onPlayerReady", this);
            }
        );

        //this.player.vhs.options_.externHls.GOAL_BUFFER_LENGTH = 60;
        //this.player.vhs.options_.externHls.MAX_GOAL_BUFFER_LENGTH = 80;

        const v = document.querySelector('video')
        v.addEventListener('pause', (event) => {
          document
                .getElementById('toggler')
                .innerText = 'REPRODUCIR';
        });

        v.addEventListener('play', (event) => {
          document
                .getElementById('toggler')
                .innerText = 'PAUSAR';
        });

        v.addEventListener('ended', (event) => {
            localStorage.removeItem('data_' + this.props.mid)
            window.location.href = "/finished";
          });

        
        

        setTimeout(
            function() {
                
                if(this.props.mid !== '') {
                    if (localStorage.getItem('data_' + this.props.mid) === null) {
                     
                    }else{
                      this.setTimeManual(localStorage.getItem('data_' + this.props.mid));
                    }
                  }

                  
            }
            .bind(this),
            3000
        );

        setTimeout(
            function() {
                
        v.play();

    }
    .bind(this),
    3100
);
 


        if (this.videoNode) {
            this
                .videoNode
                .setAttribute("webkit-playsinline", true);
            this
                .videoNode
                .setAttribute("playsinline", true);
        }

        this.intervalId = setInterval(this.savetime.bind(this), 10000);
    }

    savetime() {
        var videoElement = document.querySelector("video");
        const progress = (videoElement.currentTime / videoElement.duration) * 100;

        if(this.props.mid !== '') {
            localStorage.setItem('data_' + this.props.mid, progress);
        }
    }

    toggle() {
        var video = document.querySelector("video");
        console.info(video)
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }

    }

    // destroy player on unmount
    componentWillUnmount() {

        clearInterval(this.intervalId);

        if (this.player) {
            this
                .player
                .dispose();
        }
        
    }

     handleOnTimeUpdate = () => {
        var videoElement = document.querySelector("video");
        const progress = (videoElement.currentTime / videoElement.duration) * 100;
        
        this.setState({
            currently: progress
        })
      }

      setTime = (event) => {
        var videoElement = document.querySelector("video");
        const manualChange = Number(event.target.value);
        videoElement.currentTime = (videoElement.duration / 100) * manualChange;
        
        this.setState({
            currently: manualChange
        })
    }

    setTimeManual = (event) => {
        var videoElement = document.querySelector("video");
        const manualChange = Number(event);
        console.log(videoElement)
        videoElement.currentTime = (videoElement.duration / 100) * manualChange;
        
        this.setState({
            currently: manualChange
        })
    
    }
     
    render() {
        
        return (
            <div
                id="equisde"
                style={{
                    width: '100%',
                    height: '100vh',
                    "boxShadow":"1px 1px 48px 48px rgba(0,0,0,0.59) inset","WebkitBoxShadow":"1px 1px 48px 48px rgba(0,0,0,0.59) inset","MozBoxShadow":"1px 1px 48px 48px rgba(0,0,0,0.59) inset"
                }}>

                    <div style={{zIndex: '1000', position:'fixed', height: '100%', top: '0', left: '-100px',right: '-100px', boxShadow: '-1px -3px 76px 76px rgba(0,0,0,0.86) inset'}}></div>
                    <div className="top-5 fixed left-2 lg:left-10" style={{zIndex: '10000'}}>
                    <h1 className="text-3xl lg:text-4xl text-white" style={{fontWeight: '900'}}><span className="text-2xl lg:text-3xl text-white" style={{fontWeight: '500'}}>Estas viendo:</span> {this.props.title}</h1>
                    </div>
                <div
                    style={{
                        width: '100%',
                        height: '100%'
                    }}>
                    <div
                        data-vjs-player="data-vjs-player"
                        style={{
                            width: '100%',
                            height: '100%'
                        }}>
                        <video
                            ref={node => (this.videoNode = node)}
                            preload
                            width="100%"
                            height="100%"
                            onTimeUpdate={this.handleOnTimeUpdate}
                            className="video-js"/>
                    </div>
                </div>
                
                <nav
                    className="font-sans text-center fixed bottom-16 lg:bottom-2  sm:text-left  px-6  w-full"
                    style={{
                        width: '100%',
                        zIndex: '1001'
                    }}>
                          <input
            type="range"
            min="0"
            max="100"
            value={this.state.currently}
            onChange={(e) => this.setTime(e)}
            style={{width: '100%'}}
          />
                    <div className="flex pt-4 sm:items-baseline w-full flex-col sm:justify-between  sm:flex-row  lg:p-2">
                  
                        <div className="lg:space-x-4">
                            <div className=" float-left hidden lg:inline-block">
                                <img src="/logo.png" className="w-36 sm:max-w-sm"/>
                            </div>
                            <div className="inline-block w-full lg:w-auto">
                                <div
                                    onClick={this.toggle}
                                    id="toggler"
                                    className="hover:scale-105 w-full hover:pointer rounded-full hover:pointer font-black text-2xl bg-white py-2 px-10"
                                    style={{
                                        "boxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "WebkitBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "MozBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)"
                                    }}>
                                    REPRODUCIR
                                </div>
                            </div>

                        </div>

                    
                    <div className="block lg:hidden py-1"></div>
                    <div>
                        <div className="lg:space-x-4 ...">
                            <div className="hidden lg:inline-block">
                                <div
                                    onClick={this.props.openfs}
                                    id="fs"
                                    style={{
                                        "boxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "WebkitBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "MozBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)"
                                    }}
                                    className="hover:scale-105 hover:pointer rounded-full hover:pointer font-black text-2xl bg-white py-2 px-10">
                                    {
                                        this.props.fullS
                                            ? <a>QUITAR PANTALLA COMPLETA</a>
                                            : <a >PANTALLA COMPLETA</a>
                                    }
                                </div>
                            </div>
                            <div className="inline-block w-full lg:w-auto">
                              <a href="/">
                                <div
                                    style={{
                                        "boxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "WebkitBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)",
                                        "MozBoxShadow" : "-1px 1px 141px 40px rgba(0,0,0,0.75)"
                                    }}
                                    className="w-full hover:scale-105 hover:pointer rounded-full  hover:pointer font-black text-2xl bg-white py-2 px-10">
                                    SALIR
                                </div>
                                </a>
                            </div>

                        </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}