import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';

function Finished(props) {

    const [message, setMessage] = useState('');

    function send() {
        window
            .Rollbar
            .info(
                "Solicitud Prioritaria de Ayuda. DATA => email: " + props.user.email + ", consu" +
                        "lta: " + message,
                {
                    email: props.email,
                    message: message
                }
            );

        alert('Listo, pronto te contactaremos')

        window.location.href = "/";
    }

    return (
        <main>
            <div
                className="p-5 mt-5 w-full shadow-lg"
                style={{
                    height: '80px',
                    background: 'white'
                }}>
                <h2
                    className="text-3xl float-right text-left text-black md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Felicitaciones</h2>

                <Link to="/">
                    <button
                        class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 mr-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"/>
                        </svg>
                        <span className="text-2xl bold font-bold">IR A INICIO</span>
                    </button>
                </Link>
            </div>
            <div
                className="container p-0"
                style={{
                    background: 'rgb(17,24,39)'
                }}>

                     < div className = "pt-32 pb-8" > <h1 className="text-white font-bold text-5xl mb-5">Felicitaciones</h1>
                            <h2 className="text-gray-200 font-bold text-3xl">Has finalizado este contenido, esperamos que te gustara. Para continuar y poder seguir disfrutando de Abue, presiona el botón de abajo</h2>
                        </div>

<a href="/" className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl w-full  px-7 py-7 text-3xl  mb-3 cursor-pointer bg-red-800 text-center mt-8 font-black"
                                        style={{
                                            borderRadius: '1.5rem',
                                            textAlign: 'center',
                                            maxWidth: '500px',
                                            margin: 'auto'
                                        }}>
                                            IR AL INICIO
                                            </a>
                        

              
            </div>

        </main>
    );
}
export default Finished;