import React, {useEffect, useState} from "react";
import VideoJS from './VideoJS' // point to where the functional component is stored
import './Player.module.css';
const Player = (props) => {

    const [fullS, setFullS] = useState(false);
    const [bvideo, setBVideo] = useState('');
    const [videoTitle, setVideoTitle] = useState('');
    const [movieId, setMovieId] = useState('');

    const openfs = () => {
        let elem = document.body;

        if (fullS == false) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {/* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {/* IE11 */
                elem.msRequestFullscreen();
            }
            setFullS(true)
        } else {
            document.exitFullscreen();
            setFullS(false)
        }

    }

    useEffect(() => {


       

        let insular = '';
        let type = 'none';

        if (props.props.match.params.video_id) {
            insular = props.props.match.params.video_id;
            type = 'videos'
        } else if (props.props.match.params.course_id) {
            insular = props.props.match.params.course_id;
            type = 'learn';
        } else {
            alert('Error');
        }

        setMovieId(insular);
        const response = props
            .db
            .collection(type)
            .doc(atob(insular));

            
        response
            .get()
            .then((doc) => {
                if (!doc.exists) {
                    alert('No existente');
                }

                setBVideo(doc
                  .data()
                  .video_url)

                  document.title = doc.data().title + " | Abue.org"
                  
                setVideoTitle(doc.data().title);
            })
            .catch((error) => {
                alert('Error');
            });

        props.disableNavbar();

        return function cleanup() {
            props.enableNavbar();
        };
    }, []);

    const videoRef = React.useRef(null);

     //this.player.vhs.options_.externHls.GOAL_BUFFER_LENGTH = 60;
        //this.player.vhs.options_.externHls.MAX_GOAL_BUFFER_LENGTH = 80;

    const videoJsOptions = { // lookup the options in the docs for more options
        controls: false,
        responsive: true,
        poster: "/th3l0ad3r.gif",
        controlBar: {
            PictureInPictureToggle: false,
            playToggle: false,
            FullscreenToggle: false,
            volumeMenuButton: false
        },
        loadingSpinner: true,
        bigPlayButton: false,
        fluid: false,
        sources: [
            {
                src: bvideo,
                type: 'video/mp4'
            }
        ]
    }

    return (
    <>
    {bvideo !== '' &&
      <VideoJS
        videoRef={videoRef}
        fullS={fullS}
        title={videoTitle}
        openfs={openfs}
        mid={movieId}
        options={videoJsOptions}/>
    }
    </>
    )
}

export default Player;