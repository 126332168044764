import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

import Speech from "speak-tts";
import PlaceCard from './PlaceCard';

const speech = new Speech();

const DiscoverPage = (props) => {

    const [savedData, setSavedData] = useState([]);

    const [speechEnabled, setSpeechEnabled] = useState(false);

    function speak() {
        speech
            .speak({text: savedData.descripcion})
            .then(() => {
                console.log("Success !")
            })
            .catch(e => {
                console.error("An error occurred :", e)
            })
        }

    const fetchData = async () => {

        const response = props
            .db
            .collection('ciudades')
            .doc(atob(props.props.match.params.course_id));

        const lugares = response.collection('lugares');
        const data = await response.get();

        const data_lake = await lugares.get();

        if (data.exists) {
            let dat = data.data()

            let empty_lake = [];

            data_lake.forEach(element => {
                empty_lake.push(element.data());
            });

            setSavedData({
                descripcion: dat.descripcion,
                img: dat.img,
                title: dat.title,
                game_id: dat.game_id
            });
        }
    }

    useEffect(() => {
        fetchData();

        if (speech.hasBrowserSupport()) { // returns a boolean

            speech
                .init({
                    'volume': 1,
                    'lang': 'es-US',
                    'rate': 1,
                    'pitch': 1,
                    'voice': 'Google español de Estados Unidos',
                    'splitSentences': true
                })
                .then((data) => {
                    setSpeechEnabled(true);
                })
                .catch(e => {
                    console.error("An error occured while initializing Audio Support : ", e)
                })
            }
    }, []);

    return (
        <main>

            <div
                className="p-5 mt-5 w-full shadow-lg"
                style={{
                    height: '80px',
                    background: '#FFA900'
                }}>
                <h2
                    className="text-3xl float-right text-left text-black md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Jugando a {savedData.title || ''}</h2>

                <Link to="/discover">
                    <button
                        class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 mr-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"/>
                        </svg>
                        <span className="text-2xl bold font-bold">VOLVER</span>
                    </button>
                </Link>
            </div>

            {savedData.game_id ?
            <iframe
                src={"https://games.abue.org/" + savedData.game_id}
                frameborder="0"
                className="shadow-2xl w-full"
                style={{
                    height: '80vh',
                    width: '100vw',
                }}></iframe>

                :
                <div className="pt-10 text-white font-black text-2xl">Cargando...</div>
            }
        </main>
    );

}
export default DiscoverPage;