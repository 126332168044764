import React, {useEffect, useState} from 'react';
import Speech from "speak-tts";
import { Link } from 'react-router-dom';
const speech = new Speech();

const LearnSimpleCard = (props) => {
    const [speechEnabled, setSpeechEnabled] = useState(false);

    function speak() {
        speech
            .speak({text: props.text})
            .then(() => {
                console.log("Success !")
            })
            .catch(e => {
                console.error("An error occurred :", e)
            })
        }

    useEffect(() => {
        if (speech.hasBrowserSupport()) { // returns a boolean

            speech
                .init({
                    'volume': 1,
                    'lang': 'es-US',
                    'rate': 1,
                    'pitch': 1,
                    'voice': 'Google español de Estados Unidos',
                    'splitSentences': true
                })
                .then((data) => {
                    setSpeechEnabled(true);
                })
                .catch(e => {
                    console.error("An error occured while initializing Audio Support : ", e)
                })
            }
    }, []);

    return (
        <div
            className="bg-gray-800 w-full hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white mb-5 rounded-3xl p-4">
            <div className="flex-none lg:flex">
                
                <div
                    className="w-full h-full"
                    style={{
                        maxWidth: '250px',
                        maxHeight: props.hr ? '230px' : 'auto',
                        margin: 'auto'
                    }}>
                        
                    <img src={props.image} alt="Abue" className="w-full rounded-3xl"/>
                </div>
                <div className="flex-auto lg:ml-3 justify-evenly pt-2">
                    <div className="flex flex-wrap ">
                        <h2
                            className="flex-auto text-center lg:text-left text-bold  break-words font-bold text-4xl lg:text-5xl "
                            style={{
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                marginBottom: props.noHr ? '15px' : '2px'
                            }}>{props.title}</h2>
                    </div>

                    {props.noHr &&
                      <>

                      

                      <Link to={'/player_en/' + btoa(props.id)} className="mt-5">
                                              <div
                                                  className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-xl w-100  px-4 py-4 text-xl font-bold  mb-3 cursor-pointer"
                                                  style={{
                                                      backgroundColor: '#f50057',
                                                      color: 'white',
                                                      wordWrap: 'break-word'
                                                  }}>
                                                  <div
                                                      className="row"
                                                      style={{
                                                          width: '100%'
                                                      }}>
                                                      <div className="col-3">
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="h-8 w-8"
                                                              viewBox="0 0 20 20"
                                                              fill="currentColor">
                                                              <path
                                                                  fillRule="evenodd"
                                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                  clipRule="evenodd"/>
                                                          </svg>
                                                      </div>
                                                      <div className="col-9 font-bold text-right pr-0">
                                                          <p className="text-right text-2xl">Empezar</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </Link>
                      
                                          <Link to={'/learn/' + btoa(props.id)} params={{ video: props.url }}>
                                          
                                              <div
                                                  className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-xl w-100  px-4 py-4 text-2xl font-bold  mb-3 cursor-pointer"
                                                  style={{
                                                      backgroundColor: 'white',
                                                      color: 'black',
                                                      wordWrap: 'break-word'
                                                  }}>
                                                  <div
                                                      className="row"
                                                      style={{
                                                          width: '100%'
                                                      }}>
                                                      <div className="col-3">
                                                          <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="h-8 w-8"
                                                              viewBox="0 0 20 20"
                                                              fill="currentColor">
                                                              <path
                                                                  d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                                          </svg>
                                                      </div>
                                                      <div className="col-9 font-bold text-right pr-0">
                                                          <p className="text-right">Leer más</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          
                                          </Link>
                                  </>}

                    {!props.noHr && <div
                                className="mt-3"
                                style={{
                                    height: '5px',
                                    background: '#bdbdbd',
                                    borderRadius: '20px',
                                    width: '100%',
                                }}></div>}

                    {!props.noHr &&
                    <div
                        className="block pt-3 text-sm font-medium"
                        style={{
                            width: '100%'
                        }}>
                        <p
                            className="text-xl text-center lg:text-left mb-5"
                            style={{
                                paddingLeft: '15px',
                                paddingRight: '15px'
                            }}>{props.text}</p>

                        {
                            !props.noHr && speechEnabled && <div
                                    onClick={speak}
                                    title="Escuchar"
                                    style={{
                                        float: 'right',
                                        marginRight: '25',
                                        padding: '10'
                                    }}
                                    className="cursor-pointer mr-5 bg-gray-700 rounded-full hover:bg-gray-500">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"/>
                                    </svg>
                                </div>
                        }

                    </div>
                        }
                

                </div>

            </div>

{!props.noHr &&
            <div
                className="row"
                style={{
                    width: '100%',
                    margin: 0,
                    paddingTop: '15px'
                }}>
                <div
                    className="lg:col-6 lg:pl-0 lg:p-0"
                    style={{
                        paddingLeft: '0px !important',
                        padding: '0px'
                    }}>
                    <Link to={'/player_en/' + btoa(props.id)}>
                        <div
                            className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-xl w-100  px-4 py-4 text-xl font-bold  mb-3 cursor-pointer"
                            style={{
                                backgroundColor: '#f50057',
                                color: 'white',
                                wordWrap: 'break-word'
                            }}>
                            <div
                                className="row"
                                style={{
                                    width: '100%'
                                }}>
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                            clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right text-2xl">Empezar</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div
                    className="lg:col-6 lg:pl-3.5 lg:p-0"
                    style={{
                        padding: '0',
                    }}>
                    <Link to={'/learn/' + btoa(props.id)}>
                        <div
                            className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-xl w-100  px-4 py-4 text-2xl font-bold  mb-3 cursor-pointer"
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                wordWrap: 'break-word'
                            }}>
                            <div
                                className="row"
                                style={{
                                    width: '100%'
                                }}>
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right">Leer más</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            

                            }
        </div>
    );

}
export default LearnSimpleCard;