import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './Dashboard.module.css';
import MovieCard from '../Components/MovieCard';
import Button from '../Components/Button';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import * as firebaseui from 'firebaseui'
const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            var user = authResult.user;
            var credential = authResult.credential;
            var isNewUser = authResult.additionalUserInfo.isNewUser;
            var providerId = authResult.additionalUserInfo.providerId;
            var operationType = authResult.operationType;
            // Do something with the returned AuthResult. Return type determines whether we
            // continue the redirect automatically or whether we leave that to developer to
            // handle.
            return true;
        },
        signInFailure: function (error) {
            // Some unrecoverable error occurred during sign-in. Return a promise when error
            // handling is completed and FirebaseUI will reset, clearing any UI. This
            // commonly occurs for error code 'firebaseui/anonymous-upgrade-merge-conflict'
            // when merge conflict occurs. Check below for more details on this. return
            // handleUIError(error);
        }
    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow: 'popup',
    signInSuccessUrl: '', //Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // Other config options...
}
function Movies(props) {

    const [blogs, setBlogs] = useState([])
    const [globalCategory, setGlobalCategory] = useState('all');
    const fetchBlogs = async () => {

        let temp_blogs = [];
        const response = props
            .db
            .collection('videos');
        const data = await response.get();
        data
            .docs
            .forEach(item => {

                temp_blogs.push({data: item.data(), uid: item.id})

            })

            setGlobalCategory('all');
        setBlogs(temp_blogs)

    }

    const changeCategory = async (category) => {

        setGlobalCategory(category);

        let temp_blogs = [];
        const response = props
            .db
            .collection('videos')
            .where("category", "==", category);
        const data = await response.get();
        data
            .docs
            .forEach(item => {

                temp_blogs.push({data: item.data(), uid: item.id})

            })

        setBlogs(temp_blogs)

    }

    useEffect(() => {
        fetchBlogs();

    }, [])

    return (
        <div>
            {
                props.loading
                    ? <p>Loading..</p>
                    : (
                            !props.loggedin
                            ? <React.Fragment>
                                <p>Please sign in to see this page.</p>
                                <StyledFirebaseAuth
                                    uiConfig={uiConfig}
                                    firebaseAuth={firebase.auth()}
                                    className={classes.emailbox}/>
                            </React.Fragment>
                            : <main>
                                <div
                                    className="p-5 mt-5 w-full bg-white shadow-lg"
                                    style={{
                                        height: '80px'
                                    }}>
                                    <h2
                                        className="text-3xl float-right text-left text-gray-900 md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Películas</h2>

                                    <Link to="/">
                                        <button
                                            class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-7 w-7 mr-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                                    clipRule="evenodd"/>
                                            </svg>
                                            <span className="text-2xl bold font-bold">VOLVER</span>
                                        </button>
                                    </Link>
                                </div>
                                
                                <div className="container  w-full mt-10 p-0">
                                {globalCategory == 'all' &&
                                    <div
                                        className="container w-full transform transition delay-150 duration-1000 ease-in-out hover:scale-95 mt-10 bg-indigo-500 shadow-2xl rounded-lg mx-auto text-center py-12 mt-4"
                                        style={{
                                            background: 'url(https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/photos_m' +
                                                    'ovies%2Fimage.jpg?alt=media&token=d51d1b61-3c83-4a0d-a904-ae60cc242cb4) no-rep' +
                                                    'eat center center',
                                            WebkitBackgroundSize: 'cover',
                                            MozBackgroundSize: 'cover',
                                            OBackgroundSize: 'cover',
                                            backgroundSize: 'cover'
                                        }}>
                                        <div className="lg:m-10">
                                            <h2
                                                className="text-4xl lg:text-5xl leading-9 font-bold tracking-tight text-white sm:text-5xl sm:leading-10">
                                                COMUNICADOS
                                            </h2>
                                            <p className="text-2xl lg:text-3xl mt-8 text-white font-bold">Un documental sobre los adultos mayores y la tecnología</p>
                                            <div className="mt-8 flex justify-center">
                                                <a
                                                    href="/player/bUdwcDNRem9lZFh3a0ZabTVLcDU="
                                                    style={{

                                                        maxWidth: '400px',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-2xl lg:text-3xl  mb-3 cursor-pointer"
                                                        style={{
                                                            backgroundColor: 'white',
                                                            color: 'black',
                                                            wordWrap: 'break-word',
                                                            maxWidth: '400px',
                                                            width: '100%'
                                                        }}>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                width: '100%'
                                                            }}>
                                                            <div className="col-3">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-8 w-8"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor">
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                        clipRule="evenodd"/>
                                                                </svg>
                                                            </div>
                                                            <div className="col-9 font-bold text-right pr-0">
                                                                <p className="text-right">Empezar a ver</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {globalCategory == 'all' &&
                                        <div className="content container mt-10 p-0">
                                       
                                       <div className="text-center">
                                            <h2 className="text-4xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">
                                                Lo más visto</h2>
                                        </div>
                                        <div className="grid mt-8  gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
                                            <div className="flex flex-col space-y-4">
                                                <MovieCard noHr type="movie" id="Z03btQ3W0WdnbHKMVYGg" image="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/photos_movies%2Feltiroteoshort.jpg?alt=media&token=536ed989-a6f5-4c92-b235-d0c8747c8b7e" title="El Tiroteo"></MovieCard>
                                            </div>
                                            <div className="flex flex-col space-y-4">
                                                <MovieCard noHr type="movie" id="bajI7KC1Q9UDC4qLSL7J" image="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/photos_movies%2Fhombre_ind_quadra.jpg?alt=media&token=bb37c973-f7e3-4073-84fb-cc3ccc202ba9" title="El hombre indestructible"></MovieCard>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                        <div className="text-center my-10">
                                            <h2 className="text-2xl lg:text-4xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">
                                               Explorar todo el contenido</h2>
                                        </div>

                                    <div class="flex flex-wrap -mx-2 mt-5 overflow-hidden">
                                        <div class="my-2 px-2 hidden lg:block w-4/12 overflow-hidden">
                                            <div
                                                className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  rounded-3xl p-4">

                                                {
                                                    globalCategory != 'all' && <div
                                                            onClick={fetchBlogs}
                                                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                            style={{
                                                                backgroundColor: 'rgb(245, 0, 87)',
                                                                color: 'white',
                                                                wordWrap: 'break-word',
                                                                width: '100%'
                                                            }}>
                                                            <div
                                                                className="row"
                                                                style={{
                                                                    width: '100%'
                                                                }}>
                                                                <div className="col-3">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-8 w-8"
                                                                        viewBox="0 0 20 20"
                                                                        fill="currentColor">
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                            clipRule="evenodd"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="col-9 font-bold text-right pr-0">
                                                                    <p className="text-right">Ver Todo</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                               
                                                <div
                                                    onClick={() => changeCategory('documental')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Documentales</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => changeCategory('religiosa')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Religiosas</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => changeCategory('drama')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Drama</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => changeCategory('comedia')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Comedia</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <div
                                                    onClick={() => changeCategory('accion')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Acción</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => changeCategory('aventura')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Aventura</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => changeCategory('romance')}
                                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        wordWrap: 'break-word',
                                                        width: '100%'
                                                    }}>
                                                    <div
                                                        className="row"
                                                        style={{
                                                            width: '100%'
                                                        }}>
                                                        <div className="col-3">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-8 w-8"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"/>
                                                            </svg>
                                                        </div>
                                                        <div className="col-9 font-bold text-right pr-0">
                                                            <p className="text-right">Romance</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="my-2 lg:px-2 w-full lg:w-8/12 overflow-hidden">
                                            {
                                                blogs.map(function (x) {
                                                    return <MovieCard
                                                        key={x.uid}
                                                        id={x.uid}
                                                        title={x.data.title}
                                                        image={x.data.image}
                                                        text={x.data.description}></MovieCard>
                                                })
                                            }

                                        </div>

                                    </div>

                                </div>

                            </main>
                        )
            }
        </div>

    );

}
export default Movies;