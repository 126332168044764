import React, {Component} from 'react';
import classes from './Home.module.css';
import {Route, Link, Switch} from 'react-router-dom';
import Landing from './Landing';
import Dashboard from './Dashboard';
import Movies from './Movies';
import logo from "../logo.png";
import Player from "../Components/Player";
import LearnPlayer from "../Components/LearnPlayer";
import firebase from 'firebase';
import Learn from './Learn';
import News from './News';
import Explore from './Explore';
import LearnPage from '../Components/LearnPage';
import DiscoverPage from '../Components/DiscoverPage';
import MoviePage from '../Components/MoviePage';
import Ayuda from './Ayuda';
import Finished from './Finished';

const firebaseConfig = {
    apiKey: "AIzaSyCcS0Vl0GKa4LVbuD6oe2hc4manz-IBITU",
    authDomain: "abueorg.firebaseapp.com",
    projectId: "abueorg",
    storageBucket: "abueorg.appspot.com",
    messagingSenderId: "1011171075086",
    appId: "1:1011171075086:web:3d0f56070a7062007e485f",
    measurementId: "G-F19MP3SCS2"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const messaging = firebase.messaging();

const preguntarPermisos = async () => {
    try {

        //await messaging.requestPermission();
        await Notification.requestPermission().then(async permission => {
            if (permission === "denied") {
                console.log("Permission wasn't granted. Allow a retry.");
                return;
            } else if (permission === "default") {
                console.log("The permission request was dismissed.");
                return;
            }
            const token = await messaging.getToken();
            console.log("user token: ", token);

            return token;
        });
    } catch (error) {
        console.error(error);
    }
};

const db = firebase.firestore();
class Home extends Component {

    state = {
        overlaywidth: 0,
        loggedin: null,
        loading: true,
        navbar: true,
        user: {}
    }

    openOverlay = () => {
        this.setState({overlaywidth: 100})
    }
    closeOverlay = () => {
        this.setState({overlaywidth: 0})
    }

    singOutUser = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                // Sign-out successful.
                window.location.replace("https://abue.org");
                this.closeOverlay();
            })
            .catch(function (error) {
                // An error happened.
            })
        }

    disableNavbar = () => {
        this.setState({navbar: false})
    }

    enableNavbar = () => {
        this.setState({navbar: true})
    }

    componentWillMount() {
        firebase
            .auth()
            .onAuthStateChanged((user) => {
                if (user) {
                    preguntarPermisos()
                    this.setState({loggedin: true, loading: false, user: user})
                } else {
                    this.setState({loggedin: false, loading: false, user: user}) // No user is signed in.
                }
            });
    }

    componentDidUpdate() {
        //console.clear();
    }

    render() {
        return (
            <React.Fragment>

                <div
                    style={{
                        width: this.state.overlaywidth + '%',
                        zIndex: '10000000000000000'
                    }}
                    className={classes.overlay}>


                    <div className={classes.overlaycontent}>

                        <img
                            src={logo}
                            className="w-64 sm:max-w-sm"
                            style={{
                                margin: 'auto'
                            }}/>
                        <ul className="mt-8 pb-8">
                            <li onClick={this.closeOverlay}>

                                <div
                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                    style={{
                                        backgroundColor: '#4B3869',
                                        color: 'white',
                                        wordWrap: 'break-word',
                                        maxWidth: '600px',
                                        margin: 'auto'
                                    }}>
                                    <div
                                        className="row"
                                        style={{
                                            width: '100%'
                                        }}>
                                        <div className="col-3">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-10 w-10"
                                                viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Cerrar Menu</p>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <div
                                className="mt-8"
                                style={{
                                    height: '5px',
                                    background: '#bdbdbd',
                                    borderRadius: '20px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '100%',
                                    maxWidth: '650px'
                                }}></div>

                            {this.state.loggedin && !this.state.loading && 
                            <li onClick={this.closeOverlay} className="mt-8">
                                <Link to="/learn">
                                    <div
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: '#f50057',
                                            color: 'white',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-10 w-10"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path d="M12 14l9-5-9-5-9 5 9 5z"/>
                                                    <path
                                                        d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Aprender</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            }
                            {this.state.loggedin && !this.state.loading && 
                            <li onClick={this.closeOverlay}>
                                <Link to="/movies">
                                    <div
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: 'white',
                                            color: 'black',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-10 w-10"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Películas y Vídeos</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            }
                            {this.state.loggedin && !this.state.loading && 
                            <li onClick={this.closeOverlay} className="hidden lg:block">

                                <Link to="/discover">
                                    <div
                                        className=" hidden lg:block flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: '#FFA900',
                                            color: 'black',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-10 w-10"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path
                                                        d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Juegos</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            }
                            {this.state.loggedin && !this.state.loading && 
                            <li onClick={this.closeOverlay}>
                                <Link to="/news">
                                    <div
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: 'black',
                                            color: 'white',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-10 w-10"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                                                        clipRule="evenodd"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Leer Noticias</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            }

                            {this.state.loggedin && !this.state.loading ?
                            <li onClick={this.closeOverlay}>
                                <Link to="/ayuda">
                                    <div
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: '#22577A',
                                            color: 'white',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-10 w-10"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                        clip-rule="evenodd"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Ayuda</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            :
                            <li onClick={this.closeOverlay} className="mt-8">
                                <Link to="/ayuda">
                                    <div
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                        style={{
                                            backgroundColor: '#22577A',
                                            color: 'white',
                                            wordWrap: 'break-word',
                                            maxWidth: '600px',
                                            margin: 'auto'
                                        }}>
                                        <div
                                            className="row"
                                            style={{
                                                width: '100%'
                                            }}>
                                            <div className="col-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-10 w-10"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                        clip-rule="evenodd"/>
                                                </svg>
                                            </div>
                                            <div className="col-9 font-bold text-right pr-0">
                                                <p className="text-right">Ayuda</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            }
                            {this.state.loggedin && !this.state.loading && 

                            <li onClick={this.singOutUser}>
                                <div
                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-8 py-8 text-4xl  mb-3 cursor-pointer"
                                    style={{
                                        backgroundColor: '#A12568',
                                        color: 'white',
                                        wordWrap: 'break-word',
                                        maxWidth: '600px',
                                        margin: 'auto'
                                    }}>

                                    
                                    <div
                                        className="row"
                                        style={{
                                            width: '100%'
                                        }}>
                                        <div className="col-3">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-10 w-10"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Salir de Abue</p>
                                        </div>
                                    </div>
                                
                                </div>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
              
                <div className={classes.Container}>
                    {
                        this.state.navbar && this.state.loggedin && !this.state.loading && <div
                                className={classes.navbarcontainer}
                                style={{
                                    marginRight: 'auto',
                                    marginLeft: 'auto'
                                }}>
                                <div
                                    className={classes.logocol}
                                    style={{
                                        float: 'left'
                                    }}>
                                    <Link to=""><img src={logo} class="w-32 sm:max-w-sm"/></Link>
                                </div>

                                <div
                                    onClick={this.openOverlay}
                                    style={{
                                        float: 'right'
                                    }}
                                    className="cursor-pointer rounded-full text-black shadow-2xl hover:pointer font-black text-2xl bg-white py-2 px-10">
                                    <a>MENU</a>
                                </div>
                            </div>
                    }

                    <div
                        className={this.state.navbar
                            ? classes.contentcontainer
                            : classes.contentcontainer2}>
                        <Switch>
                            <Route
                                path='/'
                                exact="exact"
                                render={() => <Landing loading={this.state.loading} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/ayuda'
                                exact="exact"
                                render={() => <Ayuda loading={this.state.loading} user={this.state.user} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/dashboard'
                                exact="exact"
                                render={() => <Dashboard loading={this.state.loading} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/movies'
                                exact="exact"
                                render={() => <Movies loading={this.state.loading} db={db} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/learn'
                                exact="exact"
                                render={() => <Learn loading={this.state.loading} db={db} loggedin={this.state.loggedin}/>
                                }
                            />

                            <Route
                                path='/finished'
                                exact="exact"
                                render={() => <Finished loading={this.state.loading} db={db} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/news'
                                exact="exact"
                                render={() => <News loading={this.state.loading} db={db} loggedin={this.state.loggedin}/>
                                }
                            />
                            <Route
                                path='/discover'
                                exact="exact"
                                render={() => <Explore loading={this.state.loading} db={db} loggedin={this.state.loggedin}/>
                                }
                            />

                            <Route
                                path='/discover/:course_id'
                                exact="exact"
                                render={(props) => <DiscoverPage
                                    enableNavbar={this.enableNavbar}
                                    db={db}
                                    disableNavbar={this.disableNavbar}
                                    props={props}/>
                                }
                            />

                                {this.state.loggedin &&
                            <Route
                                path='/player/:video_id'
                                exact="exact"
                                render={(props) => <Player
                                    enableNavbar={this.enableNavbar}
                                    db={db}
                                    loggedin={this.state.loggedin}
                                    disableNavbar={this.disableNavbar}
                                    props={props}/>
                                }
                            />
                                }
                            {this.state.loggedin &&
                            <Route
                                path='/learn_player/:course_id'
                                exact="exact"
                                render={(props) => <LearnPlayer
                                    db={db}
                                    props={props}/>
                                }
                            />
                            }
                            {this.state.loggedin &&
                            <Route
                                path='/player_en/:course_id'
                                exact="exact"
                                render={(props) => <Player
                                    enableNavbar={this.enableNavbar}
                                    db={db}
                                    disableNavbar={this.disableNavbar}
                                    props={props}/>
                                }
                            />
                            }
                            <Route
                                path='/learn/:course_id'
                                exact="exact"
                                render={(props) => <LearnPage
                                    loading={this.state.loading}
                                    db={db}
                                    loggedin={this.state.loggedin}
                                    props={props}/>
                                }
                            />

                            <Route
                                path='/movies/:movie_id'
                                exact="exact"
                                render={(props) => <MoviePage
                                    loading={this.state.loading}
                                    db={db}
                                    loggedin={this.state.loggedin}
                                    props={props}/>
                                }
                            />

                        </Switch>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;