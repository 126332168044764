import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './Dashboard.module.css';
import MovieCard from '../Components/MovieCard';
import Button from '../Components/Button';
import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import LearnCard from '../Components/LearnCard';
import * as firebaseui from 'firebaseui'

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            var user = authResult.user;
            var credential = authResult.credential;
            var isNewUser = authResult.additionalUserInfo.isNewUser;
            var providerId = authResult.additionalUserInfo.providerId;
            var operationType = authResult.operationType;
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
        },
        signInFailure: function(error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            // return handleUIError(error);
        },

    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow:'popup',
    signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // Other config options...
}
function Learn(props) {

    const [blogs,setBlogs] = useState([])
    const [frase, setFrase] = useState('');
    const [author, setAuthor] = useState('');
  
    const fetchBlogs=async()=>{ 
        const response=props.db.collection('learn');
        const data=await response.get();
        let tempBlogs = [];
        data.docs.forEach(item=>{
            tempBlogs.push(
                {
                    data: item.data(),
                    id: item.id
                });
        })
        setBlogs(tempBlogs);
  }
  useEffect(() => {

    var randomArray = [
        {frase: '"El aprendizaje nunca agota la mente"', author: 'L. Da Vinci'},
        {frase: '"Aprender es descubrir que algo es posible"', author: 'Krishnamurti'},
        {frase: '"Aprender es un proceso constante o no es nada"', author: 'Frank Tyger'},
        {frase: '"La educación no cambia al mundo, cambia a las personas que cambiaran el mundo"', author: 'Paulo Freire'},
     ];
     

     setFrase(randomArray[Math.floor(Math.random() * randomArray.length)].frase);
     setAuthor(randomArray[Math.floor(Math.random() * randomArray.length)].author);

    fetchBlogs();
  }, [])

        return(
            <div>
                {props.loading?<p>Loading..</p>:
                    (!props.loggedin?
                            <React.Fragment>
                                <p>Please sign in to see this page.</p>
                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className={classes.emailbox}/>
                            </React.Fragment>:
                            <main>
                                    <div className="p-5 mt-5 w-full shadow-lg" style={{height:'80px', background: '#f50057'}}>
                                        <h2 className="text-3xl float-right text-left text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Aprender</h2>
                                    
                                    <Link to="/">
                                        <button class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
</svg>
  <span className="text-2xl bold font-bold">VOLVER</span>
</button>
</Link>
                                    </div>
                                <div className="container p-0" style={{background: 'rgb(17,24,39)'}}>

                                    <div className="py-32">
                                        <h1 className="text-white font-bold text-5xl mb-5">{frase} <small className="text-xs">{author}</small></h1>
                                        <h2 className="text-gray-200 font-bold text-3xl">Aprendé algo nuevo e interesante de manera gratuita con nosotros</h2>
                                    </div>

                                    <div class="flex flex-wrap flex-col lg:flex-row -mx-2 overflow-hidden">
                                        {blogs.map(function(x) {
                                            return (
                                                <div class="my-2 px-2 w-full lg:w-6/12 overflow-hidden">
                                                    <LearnCard item={x.data} uid={x.id}></LearnCard>
                                                </div>
                                            );
                                        })}
                                    </div>

                            
                                </div>
                                
                            </main>
                    )}
            </div>

        );
    
}
export default Learn;