import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

import Speech from "speak-tts";

const speech = new Speech();

const LearnPage = (props) => {

    const [savedData, setSavedData] = useState([]);

    const [speechEnabled, setSpeechEnabled] = useState(false);

    function speak() {
        speech
            .speak({text: savedData.descripcion})
            .then(() => {
                console.log("Success !")
            })
            .catch(e => {
                console.error("An error occurred :", e)
            })
        }

    const fetchData = async () => {

        const response = props
            .db
            .collection('learn')
            .doc(atob(props.props.match.params.course_id));
        const data = await response.get();

        if (data.exists) {
            let dat = data.data()
            
            setSavedData(
                {descripcion: dat.descripcion, image: dat.image, title: dat.title, author: dat.author, author_img: dat.author_img}
            );
        }
    }

    useEffect(() => {
        fetchData();

        if (speech.hasBrowserSupport()) { // returns a boolean

            speech
                .init({
                    'volume': 1,
                    'lang': 'es-US',
                    'rate': 1,
                    'pitch': 1,
                    'voice': 'Google español de Estados Unidos',
                    'splitSentences': true
                })
                .then((data) => {
                    setSpeechEnabled(true);
                })
                .catch(e => {
                    console.error("An error occured while initializing Audio Support : ", e)
                })
            }
    }, []);

    return (
        <main>

            {
                savedData != [] ?? <> < meta property = "og:site_name" content = "Abue.org" /> <meta property="og:title" content={savedData.title}/>
                    <meta property="og:description" content={savedData.descripcion}/>
                    <meta property="og:image" itemprop="image" content={savedData.image}/>
                    <meta property="og:type" content="website"/>
                </>
            }
            <div
                className="p-5 mt-5 w-full shadow-lg"
                style={{
                    height: '80px',
                    background: '#f50057'
                }}>
                <h2
                    className="text-3xl float-right text-left text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Aprender</h2>

                <Link to="/learn">
                    <button
                        class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 mr-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"/>
                        </svg>
                        <span className="text-2xl bold font-bold">VOLVER</span>
                    </button>
                </Link>
            </div>

            <div
                className="row"
                style={{
                    width: '100%'
                }}>
                <div className="lg:col-5">
                    <div className="my-5 py-4 lg:pl-4 ">

                        {
                            savedData.image
                                ? <img
                                        src={savedData.image}
                                        className="rounded-3xl shadow-2xl drop-shadow-2xl"
                                        style={{
                                            width: '100%'
                                        }}></img>

                                : <SkeletonTheme color="#4B5563" highlightColor="#444">
                                        <p>
                                            <Skeleton height={250}/>
                                        </p>
                                    </SkeletonTheme>

                        }
                        <a href={"/player_en/" + props.props.match.params.course_id}>
                        <div
                            className="flex mt-5 shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                            style={{
                                backgroundColor: '#f50057',
                                color: 'white',
                                wordWrap: 'break-word'
                            }}>
                            <div
                                className="row"
                                style={{
                                    width: '100%'
                                }}>
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                            clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right">Empezar a ver</p>
                                </div>
                            </div>
                            
                        </div>
                        </a>          
                        <div
                            className="flex mt-5 shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                wordWrap: 'break-word'
                            }}
                            onClick={speak}>
                            <div
                                className="row"
                                style={{
                                    width: '100%'
                                }}>
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-8 w-8"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right">Escuchar descripción</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-7">
                    <div className="lg:my-5 py-4">

                        <h2
                            className="flex-auto text-5xl text-left pb-10 lg:py-10 text-white text-bold font-bold">{
                                savedData.title || <SkeletonTheme color="#374151" highlightColor="#444">
                                        <p>
                                            <Skeleton/>
                                        </p>
                                    </SkeletonTheme>
                            }</h2>

                        <div
                            className="pt-5 bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-left text-white rounded-3xl p-4">

                            <p className="text-xl">{
                                    savedData.descripcion || <SkeletonTheme color="#4B5563" highlightColor="#444">
                                            <p>
                                                <Skeleton count={5}/>
                                            </p>
                                        </SkeletonTheme>
                                }</p>
                        </div>

                        <h2
                            className="ml-3 flex-auto text-xl text-left pt-10 text-white text-bold font-bold">{
                                savedData.author
                                    ? 'Autor/a : '
                                    : <SkeletonTheme color="#374151" highlightColor="#444">
                                            <p>
                                                <Skeleton/>
                                            </p>
                                        </SkeletonTheme>
                            }</h2>

                        <div class=" relative flex">

                            {
                                savedData.author_img
                                    ? <img
                                            src={savedData.author_img}
                                            alt=""
                                            class="my-auto h-24 w-24 shadow-2xl drop-shadow-2xl border-gray-600 rounded-full border-4"/>

                                    : <div class="my-auto h-24 w-24 rounded-full ">
                                            <SkeletonTheme color="#4B5563" highlightColor="#444">
                                                <p>
                                                    <Skeleton height={100}/>
                                                </p>
                                            </SkeletonTheme>
                                        </div>

                            }

                            <h2
                                className="ml-5 flex-auto text-3xl text-left py-10 text-white text-bold font-bold">{
                                    savedData.author || <SkeletonTheme color="#374151" highlightColor="#444">
                                            <p>
                                                <Skeleton/>
                                            </p>
                                        </SkeletonTheme>
                                }</h2>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}
export default LearnPage;