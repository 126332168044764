import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './Dashboard.module.css';
import MovieCard from '../Components/MovieCard';
import Button from '../Components/Button';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            var user = authResult.user;
            var credential = authResult.credential;
            var isNewUser = authResult.additionalUserInfo.isNewUser;
            var providerId = authResult.additionalUserInfo.providerId;
            var operationType = authResult.operationType;
            // Do something with the returned AuthResult. Return type determines whether we
            // continue the redirect automatically or whether we leave that to developer to
            // handle.
            return true;
        },
        signInFailure: function (error) {
            // Some unrecoverable error occurred during sign-in. Return a promise when error
            // handling is completed and FirebaseUI will reset, clearing any UI. This
            // commonly occurs for error code 'firebaseui/anonymous-upgrade-merge-conflict'
            // when merge conflict occurs. Check below for more details on this. return
            // handleUIError(error);
        }
    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow: 'popup',
    signInSuccessUrl: '', //Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    // Other config options...
}
function Explore(props) {

    const [blogs, setBlogs] = useState([])
    const fetchBlogs = async () => {
        const response = props
            .db
            .collection('ciudades');

        let temp_data = [];
        const data = await response.get();
        data
            .docs
            .forEach(item => {
                temp_data.push({...item.data(), id: item.id});
            })

            setBlogs(temp_data);
    }
    useEffect(() => {
        fetchBlogs();
    }, [])

    return (
        <div>
            {
                props.loading
                    ? <p>Loading..</p>
                    : (
                            !props.loggedin
                            ? <React.Fragment>
                                <p>Please sign in to see this page.</p>
                                <StyledFirebaseAuth
                                    uiConfig={uiConfig}
                                    firebaseAuth={firebase.auth()}
                                    className={classes.emailbox}/>
                            </React.Fragment>
                            : <main>
                                <div
                                    className="p-5 mt-5 w-full shadow-lg"
                                    style={{
                                        height: '80px',
                                        background: '#FFA900'
                                    }}>
                                    <h2
                                        className="text-3xl float-right text-left text-black md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Juegos</h2>

                                    <Link to="/">
                                        <button
                                            class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-7 w-7 mr-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                                    clipRule="evenodd"/>
                                            </svg>
                                            <span className="text-2xl bold font-bold">VOLVER</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="container mt-10 p-0">

                                    <div class="flex flex-wrap -mx-2 overflow-hidden">
                                        {
                                            blogs.map(function (x) {
                                                return (
                                                    <div class="my-2 px-2 w-6/12 overflow-hidden">

                                                        <div
                                                            className="shadow-2xl transform hover:scale-105 drop-shadow-2xl text-white mb-5 rounded-3xl pt-64 px-28 pb-10"
                                                            style={{
                                                                maxWidth: '700px',
                                                                width: '100%',
                                                                background: 'url(' + x.img + ')',
                                                                backgroundSize: 'cover',
                                                                backgroundPositionY: 'bottom'
                                                            }}>
                                                            <div className="bg-gray-800 hover:bg-gray-700 text-center  p-2">
                                                                <h1 className="text-6xl font-black">{x.title}</h1>

                                                            
                                                            </div>

                                                            <a href={'/discover/' + btoa(x.id)}>
                                                                        <div
                                                                            className="flex border-8 border-gray-800 shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl w-100  px-4 py-4 text-2xl font-black  mb-3 cursor-pointer"
                                                                            style={{
                                                                                backgroundColor: 'rgb(255, 169, 0)',
                                                                                color: 'black',
                                                                                wordWrap: 'break-word',
                                                                                maxWidth: '300px'
                                                                            }}>
                                                                            <div className="row" style={{width: '100%'}}>
                                                                                <div className="col-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                         className="h-8 w-8" viewBox="0 0 20 20"
                                                                                         fill="currentColor">
                                                                                        <path fillRule="evenodd"
                                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                                              clipRule="evenodd"/>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="col-9 font-black text-right pr-0">
                                                                                    <p className="text-right">JUGAR</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                </div>

                            </main>
                        )
            }
        </div>

    );

}
export default Explore;