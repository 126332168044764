import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';

function Ayuda(props) {

    const [message, setMessage] = useState('');

    function send() {
        window
            .Rollbar
            .info(
                "Solicitud Prioritaria de Ayuda. DATA => email: " + props.user.email + ", consu" +
                        "lta: " + message,
                {
                    email: props.email,
                    message: message
                }
            );

        alert('Listo, pronto te contactaremos')

        window.location.href = "/";
    }

    return (
        <main>
            <div
                className="p-5 mt-5 w-full shadow-lg"
                style={{
                    height: '80px',
                    background: '#22577A'
                }}>
                <h2
                    className="text-3xl float-right text-left text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Ayuda</h2>

                <Link to="/">
                    <button
                        class="float-left inline-flex items-center h-10 px-5 py-5 text-white transition-colors duration-150 bg-gray-900 rounded-full focus:shadow-outline hover:bg-gray-800">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 mr-5"
                            viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                clipRule="evenodd"/>
                        </svg>
                        <span className="text-2xl bold font-bold">VOLVER</span>
                    </button>
                </Link>
            </div>
            <div
                className="container p-0"
                style={{
                    background: 'rgb(17,24,39)'
                }}>

                {false ? 
                    <> < div className = "pt-32 pb-8" > <h1 className="text-white font-bold text-5xl mb-5">¿Tiene alguna pregunta o problema?</h1>
                            <h2 className="text-gray-200 font-bold text-3xl">Por favor, contanos detalladamente para poder ayudarle lo más pronto posible</h2>
                        </div>

                        <div class="flex flex-wrap -mx-2 overflow-hidden">
                            <div
                                className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white mb-5 w-full rounded-3xl p-4"
                                style={{
                                    margin: 'auto',
                                    maxWidth: '1000px',
                                    marginBottom: '5rem'
                                }}>

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        send()
                                    }}>

                                    <textarea
                                        placeholder="Exprese escribiendo su situación para poder responderle "
                                        rows="8"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        style={{
                                            fontSize: '2rem',
                                            borderRadius: '1.5rem',
                                            color: 'black',
                                            width: '100%'
                                        }}/>

                                    <button
                                        type="submit"
                                        className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl w-full  px-7 py-7 text-3xl  mb-3 cursor-pointer bg-red-800 text-center mt-8 font-black"
                                        style={{
                                            borderRadius: '1.5rem',
                                            textAlign: 'center !important'
                                        }}>Enviar</button>
                                </form>
                            </div>
                        </div>

                    </>
                        : <> < div className = "pt-32 pb-8" > <h1 className="text-white font-bold text-5xl mb-5">Si necesita ayuda</h1>
                            <h2 className="text-gray-200 font-bold text-3xl">Le invitamos a escribirnos a nuestro WhatsApp para atenderle rapidamente</h2>
                                        <br />
                            <div className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white mb-5 mt-8 rounded-3xl p-4" style={{margin: 'auto', maxWidth: 1042, marginBottom: '10rem'}}>
                                        <h1 style={{fontSize: '3rem', fontWeight: 'bold'}}>0054-11-22526382</h1>
                            </div>

                        </div>
                    </>
                }
            </div>

        </main>
    );
}
export default Ayuda;