import React, {useEffect, useState} from 'react';
import Speech from "speak-tts";

const speech = new Speech();

const PlaceCard = (props) => {
    const [speechEnabled, setSpeechEnabled] = useState(false);

    function speak() {
        speech
            .speak({text: props.text})
            .then(() => {
                console.log("Success !")
            })
            .catch(e => {
                console.error("An error occurred :", e)
            })
        }

    useEffect(() => {
        if (speech.hasBrowserSupport()) { // returns a boolean

            speech
                .init({
                    'volume': 1,
                    'lang': 'es-US',
                    'rate': 1,
                    'pitch': 1,
                    'voice': 'Google español de Estados Unidos',
                    'splitSentences': true
                })
                .then((data) => {
                    setSpeechEnabled(true);
                })
                .catch(e => {
                    console.error("An error occured while initializing Audio Support : ", e)
                })
            }
    }, []);

    return (
        <div
            className="w-full bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white text-left rounded-3xl p-4">
            <div className="flex-none lg:flex">
                <div
                    className="w-full h-full"
                    style={{
                        maxWidth: '250px'
                    }}>
                    <img src={props.img} alt="Abue" className="w-full rounded-3xl"/>
                </div>
                <div className="flex-auto ml-3 justify-evenly ">

                    <a href={'/player/' + 2}>
                        <div
                            className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-3xl w-100  px-4 py-4 text-xl font-bold mb-3 cursor-pointer"
                            style={{
                                backgroundColor: '#3DB2FF',
                                color: 'black',
                                wordWrap: 'break-word'
                            }}>
                            <div
                                className="row"
                                style={{
                                    width: '100%'
                                }}>
                                <div className="col-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                            clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right text-2xl">Explorar</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div className="flex flex-wrap mb-5">
                        <h2
                            className="flex-auto text-5xl text-left text-bold font-bold"
                            style={{
                                paddingLeft: '15px',
                                paddingRight: '15px'
                            }}>{props.title}</h2>
                    </div>

                </div>

            </div>
        </div>
    );

}
export default PlaceCard;