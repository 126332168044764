import React,{Component} from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './Dashboard.module.css';
import { Link } from 'react-router-dom';
import MovieCard from '../Components/MovieCard';
import LearnSimpleCard from '../Components/LearnSimpleCard';
import * as firebaseui from 'firebaseui'
const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        var user = authResult.user;
        var credential = authResult.credential;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        var providerId = authResult.additionalUserInfo.providerId;
        var operationType = authResult.operationType;
        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return true;
      },
      signInFailure: function(error) {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        // return handleUIError(error);
      },
  
    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow:'popup',
    signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // Other config options...
  } 
class Dashboard extends Component{

 
render(){
return(
<div>
{this.props.loading?<p className="text-white">CARGANDO...</p>:
(!this.props.loggedin?
<React.Fragment>
    <p className="text-3xl" style={{color: 'white'}}>Por favor accede para poder ver esto</p>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className={classes.emailbox}/>
 </React.Fragment>:
        <main className="w-screen">
            <div className="py-6 md:py-12 shadow-2xl" style={{
                background: 'url(/techno.JPG) no-repeat center top',
                WebkitBackgroundSize: 'cover',
                MozBackgroundSize: 'cover',
                OBackgroundSize: 'cover',
                backgroundSize: 'cover'
            }}>
                <div className="container-fluid px-4 pb-12 ml-auto mr-0">
                    <div className="p-10 lg:p-20 text-center lg:text-right lg:max-w-3xl lg:ml-auto lg:mr-0">
                        <h1 className="text-7xl text-white font-black mb-2">¿Qué quieres hacer hoy?</h1>
                    </div>

                    <div class="flex flex-wrap sm:w-full -mx-2 overflow-hidden">

  <div class="my-2 px-2 w-full lg:w-4/12 overflow-hidden" >
    
  </div>

  <div class="lg:my-2 px-2 w-full lg:w-4/12 overflow-hidden" >
 
                                <Link to="/learn">
                                <div
                                    className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-4xl  mb-3 cursor-pointer"
                                    style={{backgroundColor: '#f50057', color: 'white', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M12 14l9-5-9-5-9 5 9 5z"/>
                                                <path
                                                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0" style={{padding: 0}}>
                                            <p className="text-right p-0">Aprender</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            <Link to="/movies">
                                <div
                                    className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                    style={{backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"/>
                                            </svg>
                                        </div>
                                        <div className="col-10 font-bold text-right pr-0" style={{padding: 0}}>
                                            <p className="text-right pr-0">Películas y Vídeos</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>

  </div>

  <div class="lg:my-2 px-2 w-full lg:w-4/12 overflow-hidden">
  <Link to="/discover" className="hidden lg:block">
                                <div
                                    className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-4xl  mb-3 cursor-pointer"
                                    style={{backgroundColor: '#FFA900', color: 'black', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Jugar</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/news">
                                <div
                                    className="flex shadow-2xl transform hover:scale-95 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                                    style={{backgroundColor: 'black', color: 'white', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Leer Noticias</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
  </div>

</div>


                </div>
            </div>
            <div className="content container mt-10 p-0">
                <div>
                    <h2 className="text-3xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Nuestras
                        recomendaciones</h2>
                </div>
                <div className="grid mt-8  gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
                    <div className="flex flex-col space-y-4">
                    <MovieCard noHr type="movie" id="Z03btQ3W0WdnbHKMVYGg" image="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/photos_movies%2Feltiroteoshort.jpg?alt=media&token=536ed989-a6f5-4c92-b235-d0c8747c8b7e" title="El Tiroteo"></MovieCard>

                    <MovieCard noHr type="movie" id="mGpp3QzoedXwkFZm5Kp5" image="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/photos_movies%2Fcomunicados.png?alt=media&token=efa6c65b-346e-4066-b248-5c165ec3cac6" title="Documental: Comunicados"></MovieCard>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <LearnSimpleCard noHr type="learn" id="P30dRPJwdBBYdBTsdoZX" image="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/recusation_trilineal.png?alt=media&token=4c1fd0ea-25e1-4ef3-9c21-0b4bb11653ea" title="¿Cómo hacer un llavero pulsera?"></LearnSimpleCard>  

                        <MovieCard noHr type="movie" id="8dmJLz54OABLcktikCzI" image="https://i.ytimg.com/vi/sEuLje3Gxvo/mqdefault.jpg" title="Amor de Madre"></MovieCard>
                    </div>
                </div>
            </div>

            <div className="content container mt-10 hidden lg:block p-0">
                <div>
                    <h2 className="text-3xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">ESTRENO DE LA SEMANA</h2>
                </div>
                <div
                    className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  rounded-3xl p-4 mt-5">
                    <div className="flex-none lg:flex">
                        <div className="w-full lg:mb-0 mb-3" style={{width: '70%'}}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/charada.jpg?alt=media&token=5a8a7680-d4c7-48b7-8d40-d6fe9451eb35"
                                 alt="Solitario" className="w-full rounded-2xl"/>
                        </div>
                        <div className="flex-auto ml-3 justify-evenly py-2" style={{width: '30%'}}>
                            <div className="flex flex-wrap ">
                                <h2 className="flex-auto text-center text-5xl py-5 text-bold font-bold">Charada</h2>
                            </div>
                            <div className="flex p-4 pb-2 mt-3 border-t border-gray-200 "/>
                            <div className="block space-x-3 text-center text-sm font-medium" style={{width: '100%'}}>
                                <p className="text-2xl ">Tras pasar unas vacaciones en una estación de esquí donde ha conocido a Peter Joshua, Reggie Lampert va a pedir el divorcio a su marido. Pero cuando llega a París descubre que éste ha sido asesinado y su apartamento está vacío.</p>

                                <Link to="/player/ME1FdUVHTmNnN0JVcnB4bFBXaEc="  className="ml-0 m-0 p-0">
                                <div
                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl cursor-pointer"
                                    style={{backgroundColor: '#f50057', color: 'white', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Ver ahora</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>

                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className="container hidden lg:block transform transition delay-150 duration-1000 ease-in-out hover:scale-95 mt-10 bg-indigo-500 shadow-2xl rounded-lg mx-auto text-center py-12 mt-4"
                style={{
                    background: 'url(/bg2.JPG) no-repeat center center',
                    WebkitBackgroundSize: 'cover',
                    MozBackgroundSize: 'cover',
                    OBackgroundSize: 'cover',
                    backgroundSize: 'cover'
                }}>
                <div className="m-28">
                    <h2 className="text-7xl leading-9 font-bold tracking-tight text-white sm:leading-10">
                        ¿Qué es la tecnología?
                    </h2>
                    <Link to="/learn/WkQzcklDOWhNYTFFSURzeEdVZXI=">
                    <div className="mt-16 flex justify-center">
                        <div
                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl  mb-3 cursor-pointer"
                            style={{backgroundColor: 'white', color: 'black', wordWrap: 'break-word', maxWidth: '300px', width: '100%'}}>
                            <div className="row" style={{width: '100%'}}>
                                <div className="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>
                                <div className="col-9 font-bold text-right pr-0">
                                    <p className="text-right">Leer más</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            <div className="content container mt-10 p-0">
                <div>
                    <h2 className="text-3xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Aprendé
                        algo nuevo e interesante</h2>
                </div>

                <div class="flex flex-wrap -mx-2 overflow-hidden mt-5 mb-10">
               
                <div class="my-2 px-2 w-full lg:w-1/4 overflow-hidden">
                <Link to="/learn/TnZJMExIUUVtSEVwSEtHQ3VzdnM=" className="w-full">
                    <div
                        className="bg-red-500 hover:bg-red-400 w-full h-80 shadow-2xl drop-shadow-2xl text-black text-center rounded-3xl p-4 justify-center flex align-center">
                        <h2 className="text-center text-5xl text-bold font-bold justify-center flex align-center"
                            style={{display: 'flex', alignItems: 'center'}}>Bijouterie</h2>
                    </div>

  </Link></div>


  <div class="my-2 px-2 w-full lg:w-1/4 overflow-hidden">
  <Link to="/learn/dDlQbmd4bDQySjNMaG5kdWtjdEc=" className="w-full">
                    <div
                        className="bg-yellow-500 hover:bg-yellow-400 w-full h-80 shadow-2xl drop-shadow-2xl text-black text-center rounded-3xl p-4 justify-center flex align-center">
                        <h2 className="text-center text-5xl text-bold font-bold justify-center flex align-center"
                            style={{display: 'flex', alignItems: 'center'}}>Helados caseros</h2>
                    </div>

</Link></div>


  <div class="my-2 px-2 w-full lg:w-1/4 overflow-hidden">
  <Link to="/learn/WUhZSTBrNmFNbGtweW9XMEVGUnY=" className="w-full">
                    <div
                        className="bg-blue-500 hover:bg-blue-400 w-full h-80 shadow-2xl drop-shadow-2xl text-black text-center rounded-3xl p-4 justify-center flex align-center">
                        <h2 className="text-center text-5xl text-bold font-bold justify-center flex align-center"
                            style={{display: 'flex', alignItems: 'center'}}>Juegos</h2>
                    </div>
  
</Link>
</div>

  <div class="my-2 px-2 w-full lg:w-1/4 overflow-hidden">
  <Link to="/learn/WkQzcklDOWhNYTFFSURzeEdVZXI=" className="w-full">
                    <div
                        className="bg-purple-500 hover:bg-purple-400 w-full h-80 shadow-2xl drop-shadow-2xl text-black text-center rounded-3xl p-4 justify-center flex align-center">
                        <h2 className="text-center text-5xl text-bold font-bold justify-center flex align-center"
                            style={{display: 'flex', alignItems: 'center'}}>Tecnología</h2>
                    </div>
 
</Link>
</div>
  

</div>

            
                <hr size={10}/>
            </div>

            <div className="content container mt-10 hidden w-full py-28 lg:block shadow-2xl" style={{
                background: 'url(/assets/images/imagex.jpg) no-repeat center top',
                WebkitBackgroundSize: 'cover',
                MozBackgroundSize: 'cover',
                OBackgroundSize: 'cover',
                backgroundSize: 'cover'
            }}>
                <div>
                    <h2 className="text-5xl text-center text-white md:text-5xl font-semibold leading-7 md:leading-10 mb-1">¿Te gustaría apoyar el proyecto?</h2>
                    <h4 className="text-3xl text-center text-white mt-8">¿Sabías que puedes donar para apoyar el proyecto y dejarnos tu mensaje?</h4>
                    <a target="_blank" href="https://cafecito.app/abue">
                    <div className="mt-8 flex justify-center">
                        <div
                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  py-7 text-3xl  mb-3 cursor-pointer"
                            style={{backgroundColor: 'white', color: 'black', wordWrap: 'break-word', maxWidth: '500px', width: '100%'}}>
                             <p className="text-center text-black w-full font-black">Apoyar desde Argentina</p>
                              
                         
                        </div>
                    </div>
                    </a>

                    <a target="_blank" href="https://www.patreon.com/abue">
                    <div className="mt-4 flex justify-center">
                        <div
                            className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-5 text-2xl  mb-3 cursor-pointer"
                            style={{backgroundColor: 'white', color: 'black', wordWrap: 'break-word', maxWidth: '400px', width: '100%'}}>
                            
                                    <p className="text-center text-bold w-full font-bold">Apoyar desde otro país</p>
                                
                          
                        </div>
                    </div>
                    </a>
                </div>

            </div>
            <div className="content container mt-10 hidden lg:block p-0">
                <div>
                    <h2 className="text-3xl text-center text-white md:text-4xl font-semibold tracking-tight leading-7 md:leading-10 mb-1 truncate">Juega gratis ahora mismo</h2>
                </div>
                <div
                    className="bg-gray-800 hover:bg-gray-700 shadow-2xl drop-shadow-2xl text-white  rounded-3xl p-4 mt-5">
                    <div className="flex-none lg:flex">
                        <div className="w-full lg:mb-0 mb-3" style={{width: '70%'}}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/abueorg.appspot.com/o/solitario.png?alt=media&token=2c87ae47-2a1c-4a54-b8b6-b6653ed7bcc1"
                                 alt="Solitario" className="w-full rounded-2xl"/>
                        </div>
                        <div className="flex-auto ml-3 justify-evenly py-2" style={{width: '30%'}}>
                            <div className="flex flex-wrap ">
                                <h2 className="flex-auto text-center text-5xl py-10 text-bold font-bold">Solitario</h2>
                            </div>
                            <div className="flex p-4 pb-2 mt-3 border-t border-gray-200 "/>
                            <div className="block space-x-3 text-center text-sm font-medium" style={{width: '100%'}}>
                                <p className="text-2xl ">Un juego de cartas interesante</p>

                                <Link to="/discover/OHhVUGZPVjJmd3NVUTJETzR1TGU="  className="ml-0 m-0 p-0">
                                <div
                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl cursor-pointer"
                                    style={{backgroundColor: '#f50057', color: 'white', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd"
                                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Jugar</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>

                                
                        

                                <Link to="/discover" className="m-0 ml-0 p-0">
                                <div
                                    className="flex shadow-2xl transform hover:scale-105 text-white drop-shadow-2xl rounded-lg w-100  px-7 py-7 text-3xl cursor-pointer"
                                    style={{backgroundColor: 'black', color: 'white', wordWrap: 'break-word'}}>
                                    <div className="row" style={{width: '100%'}}>
                                        <div className="col-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8"
                                                 viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"/>
                                            </svg>
                                        </div>
                                        <div className="col-9 font-bold text-right pr-0">
                                            <p className="text-right">Más juegos</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </main>
)}
</div>

);
}
}
export default Dashboard;