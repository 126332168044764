import React,{Component} from 'react';
import stockillustration from '../Resources/Images/splash.png';
import classes from './Landing.module.css';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {Redirect} from 'react-router-dom';
import * as firebaseui from 'firebaseui'
import '../assets/css/animate.css';
import '../assets/css/magnific-popup.css';
import '../assets/css/slick.css';
import '../assets/css/LineIcons.css';
import '../assets/css/font-awesome.min.css';


let redirect = <Redirect to="dashboard" />;  


const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      var user = authResult.user;
      var credential = authResult.credential;
      var isNewUser = authResult.additionalUserInfo.isNewUser;
      var providerId = authResult.additionalUserInfo.providerId;
      var operationType = authResult.operationType;
      // Do something with the returned AuthResult.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    signInFailure: function(error) {
      // Some unrecoverable error occurred during sign-in.
      // Return a promise when error handling is completed and FirebaseUI
      // will reset, clearing any UI. This commonly occurs for error code
      // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      // occurs. Check below for more details on this. 
      // return handleUIError(error);
    },

  },
  queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
  signInFlow:'popup',
  signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
  signInOptions: [
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // Other config options...
} 



class Landing extends Component{

  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  send(event) {
    window.Rollbar.info("Solicitud de Ayuda", {email: this.state.value});

    alert('Listo, pronto te contactaremos')
    event.preventDefault();
  }

render(){
return(
  <> 
  {this.props.loading?<p style={{fontSize: 30, color: 'white'}}>CARGANDO...</p>:
(this.props.loggedin?redirect:
<React.Fragment>
 

<div>
<link rel="stylesheet" href="assets/css/bootstrap.min.css" />

<link rel="stylesheet" href="assets/css/default.css" />

<link rel="stylesheet" href="assets/css/style.css" />


    <header className="header-area shadow-2xl" style={{marginTop: '-60px'}}>
          <div id="home" className="header-hero bg_cover" style={{background: 'rgb(17,24,39)', minHeight: '100vh', backgroundImage: 'url("assets/images/techno.JPG")', backgroundSize: 'cover'}}>
            <div className="container-fluid">

            <div className="navbar-area hidden lg:block w-full">
            <div className="absolute" style={{top: 0, right: '21px', left: '21px'}}>
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg" style={{width: '100%'}}>
                    <a className="navbar-brand" href="index.html">
                      <img src="assets/images/logo.png" alt="Logo" style={{maxWidth: '180px'}} />
                    </a>
                  
                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                      <ul id="nav" className="navbar-nav ml-auto">
                        <li className="nav-item">
                          <a className="page-scroll transform transition duration-500 ease-in-out hover:scale-105" href="#about">¿Qué es Abue?</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll transform transition duration-500 ease-in-out hover:scale-105" href="#features">Carácteristicas</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll transform transition duration-500 ease-in-out hover:scale-105" href="#why">¿Por qué?</a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll transform transition duration-500 ease-in-out hover:scale-105" href="#support">Apoyar</a>
                        </li>
                      </ul>
                    </div>
                    
                  </nav>
                </div>
              </div>
            </div>
          </div>

              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="header-hero-content text-center">
                    <h2 className="header-sub-title wow fadeInUp transform transition duration-500 ease-in-out hover:scale-105" data-wow-duration="1.3s" data-wow-delay="0.5s">Primero lo primero</h2>
                    <h3 style={{marginTop: '19px', fontSize: '3rem'}} className="header-title wow fadeInUp transform transition duration-500 ease-in-out hover:scale-105" data-wow-duration="1.3s" data-wow-delay="0.2s">Somos
                      la primera plataforma de aprendizaje y entretenimiento para abuelos y abuelas</h3>
                  
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className="transform transition duration-500 ease-in-out hover:scale-110"/>

                    <a className="page-scroll" style={{width: '100%', margin: 'auto'}} href="#about">
                      <div className="transform transition duration-500 ease-in-out hover:scale-110 shadow-2xl" style={{width: '100%', textAlign: 'center', borderRadius: '.5rem', display: 'flex', fontSize: '1.875rem', lineHeight: '2.25rem', margin: 'auto', paddingTop: '1.35rem', paddingBottom: '1.35rem', paddingLeft: '1.35rem', paddingRight: '1.35rem', boxShadow: '0 25px 50px -12px rgb(0 0 0 / 25%)', maxWidth: '300px', minHeight: '71px', backgroundColor: 'white'}}>
                        <span style={{fontSize: '26px', width: '100%', textAlign: 'center', color: 'black', fontFamily: 'Roboto,Helvetica,Arial,sans-serif', fontWeight: '500'}}>Leer más</span>
                      </div>
                    </a>

    
                  </div>
                </div>
              </div>
            </div>
            <div id="particles-1" className="particles" />
          </div>
        </header>
        <section id="about" className="services-area pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center pb-40">
                  <div className="line m-auto" />
                  <h2 className="title" style={{fontSize: '3rem'}}>
                    <span>¿Qué es </span>Abue<span>?</span>
                  </h2>
                </div>
              </div>
            </div>
            <h3 className="title text-center">Abue.org es una plataforma global de aprendizaje,
              entretenimiento e información diseñada para adultos mayores.</h3>
            <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <iframe width={1042} style={{borderRadius: '1.2rem', width: '100%'}} height={586} src="https://www.youtube.com/embed/LCQW9u2Yq3c" title="Presentando Abue" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen" />
            </div>
          </div>
        </section>
        <br /><br />
        <section id="features" className="about-area pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-last">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line" />
                    <h3 className="title" style={{fontSize: '50px', textAlign: 'left', lineHeight: '45px'}}>
                      <span>Aprende algo </span> nuevo <span>
                        y</span> gratis</h3>
                  </div>
                  <p className="text" style={{fontSize: '33px', textAlign: 'left', lineHeight: '38px'}}>Ofrecemos
                    capacitaciones gratuitas en diversos temas para siempre aprender a hacer algo
                    nuevo.</p>
                </div>
              </div>
              <div className="col-lg-6 order-lg-first">
                <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src="assets/images/mar.png" style={{width: '100%', borderRadius: '1.2rem'}} alt="about" />
                </div>
                {/* about image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </section>
        {/*====== ABOUT PART START ======*/}
        <section className="about-area pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line" />
                    <h3 className="title" style={{fontSize: '50px', textAlign: 'right', lineHeight: '45px'}}>
                      <span>Entretenimiento las </span> 24hs
                      <span /></h3>
                  </div>
                  {/* section title */}
                  <p className="text" style={{fontSize: '33px', lineHeight: '38px', textAlign: 'right'}}>Contamos con un
                    catálogo de peliculas, documentales y vídeos interesante para nunca aburrirse.</p>
                </div>
                {/* about content */}
              </div>
              <div className="col-lg-6">
                <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src="assets/images/cine.jpeg" style={{width: '100%', borderRadius: '1.2rem'}} alt="about" />
                </div>
                {/* about image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
          <div className="about-shape-1">
            <img src="assets/images/about-shape-1.svg" alt="shape" />
          </div>
        </section>
        {/*====== ABOUT PART ENDS ======*/}
        {/*====== ABOUT PART ENDS ======*/}
        <section className="about-area pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-last">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line" />
                    <h3 className="title" style={{fontSize: '50px', lineHeight: '45px', textAlign: 'left'}}>
                      <span>Juega y </span> Diviertete</h3>
                  </div>
                  {/* section title */}
                  <p className="text" style={{fontSize: '33px', lineHeight: '38px', textAlign: 'left'}}>Con Abue podrás divertirte cuando quieras con nuestros juegos interactivos.</p>
                </div>
                {/* about content */}
              </div>
              <div className="col-lg-6 order-lg-first">
                <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src="assets/images/solitario.png" style={{width: '100%', borderRadius: '1.2rem'}} alt="about" />
                </div>
                {/* about image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </section>
        <section className="about-area pt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <div className="section-title">
                    <div className="line" />
                    <h3 className="title" style={{fontSize: '50px', lineHeight: '45px', textAlign: 'right'}}>
                      <span>Mantente </span> informado
                      <span /></h3>
                  </div>
                  {/* section title */}
                  <p className="text" style={{fontSize: '33px', lineHeight: '38px', textAlign: 'right'}}>También buscamos
                    mantenerte informado con nuestra herramienta de noticias diseñada especialmente
                    para que hacerlo sea más facil e interactivo.</p>
                </div>
                {/* about content */}
              </div>
              <div className="col-lg-6">
                <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src="assets/images/news.jpeg" style={{width: '100%', borderRadius: '1.2rem'}} alt="about" />
                </div>
                {/* about image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
          <div className="about-shape-1">
            <img src="assets/images/about-shape-1.svg" alt="shape" />
          </div>
        </section>
        <br /><br />
        <section id="why" className="services-area pt-125 pb-125" style={{backgroundImage: 'url(assets/images/bg2.JPG)', backgroundSize: 'cover', marginTop: '40px', backgroundPosition: 'fixed'}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center pb-40">
                  <div className="line m-auto" />
                  <h2 className="title" style={{fontSize: '3rem'}}>
                    <span>¿Por </span> qué<span>?</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* row */}
            <h3 className="title text-center" style={{fontSize: '35px'}}>Creemos que las y los
              adultos mayores deben ser parte del siglo 21. Deben poder disfrutar de las
              maravillas tecnológicas y a la vez satisfacer sus necesiades con la misma, por
              eso creamos Abue.</h3>
          </div>
          {/* container */}
        </section>
        <br /><br />
        <section id="support" className="services-area pt-125 pb-125">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title text-center pb-40">
                  <div className="line m-auto" />
                  <h2 className="title" style={{fontSize: '3rem'}}>
                    <span>Apoyar el </span>proyecto<span />
                  </h2>
                </div>
              </div>
            </div>
            {/* row */}
            <h3 className="title text-center" style={{fontSize: '32px', fontWeight: 'bold'}}>¿Te gustaría patrocinar nuestro proyecto?, ¿Te gustaría convertirte en Abuer?</h3>
            <h3 className="title text-center mt-30" style={{fontSize: '20px', fontWeight: 'normal'}}>Si
              sos de Argentina y queres pagar en pesos, podes hacerlo a través de Cafecito,
              pero si sos de otro pais, podes patrocinarnos mediante Patreon</h3>
            <div className="container mt-30">
              <div className="row">
                <div className="col-sm-6">
                  <a href="https://cafecito.app/abue" style={{fontSize: '25px', textAlign: 'center', fontWeight: 'bold', width: '100%', padding: '10px', borderRadius: '1.2rem', background: 'white', color: 'black'}} target="_blank">
                    En pesos argentinos (Cafecito)
                  </a>
                </div>
                <div className="col-sm-6">
                  <a href="https://www.patreon.com/abue" style={{fontSize: '25px', textAlign: 'center', fontWeight: 'bold', width: '100%', padding: '10px', borderRadius: '1.2rem', background: 'white', color: 'black'}} target="_blank">
                    En otra moneda (Patreon App)
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* container */}
        </section>
        <footer id="footer" className="footer-area pt-120">
          <div className="container">
            <div className="footer-widget pb-100">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <div className="footer-about mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <a className="logo" href="#">
                      <img style={{maxWidth: '180px'}} src="assets/images/logo.png" alt="logo" />
                    </a>
                    <p className="text">Primero lo primero.</p>
                    <ul className="social">
                      <li>
                        <a href="https://twitter.com/abue_org/">
                          <i className="lni-twitter-filled" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/abue_org/">
                          <i className="lni-instagram-filled" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* footer about */}
                </div>
                <div className="col-lg-5 col-md-7 col-sm-7">
                  <div className="footer-link d-flex mt-50 justify-content-md-between">
                    <div className="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                      <div className="footer-title">
                        <h4 className="title">Links rápidos</h4>
                      </div>
                      <ul className="link">
                        <li>
                          <a target="_blank" href="https://resources.abue.org/politicas_privacidad.html">Políticas de privacidad</a>
                        </li>
                        <li>
                          <a target="_blank" href="https://resources.abue.org/condiciones_uso.html">Terminos de servicio</a>
                        </li>
                        <li>
                          <a target="_blank" href="https://www.microsoft.com/es-ar/p/abueorg/9nnh159rqzg6"><b>Descargar para Windows</b></a>
                        </li>
                        <li>
                          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dataxype.abue"><b>Descargar para Android</b></a>
                        </li>
                      </ul>
                    </div>
                    {/* footer wrapper */}
                    <div className="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
                      <div className="footer-title">
                        <h4 className="title">Recursos</h4>
                      </div>
                      <ul className="link">
                        <li>
                          <a href="/">Inicio</a>
                        </li>
                        <li>
                          <a href="#about">¿Qué es Abue.org?</a>
                        </li>
                        <li>
                          <a href="#feature">Carácteristicas</a>
                        </li>
                        <li>
                          <a href="#why">¿Por qué?</a>
                        </li>
                        <li>
                          <a href="#support">Apoyar al proyecto</a>
                        </li>
                      </ul>
                    </div>
                    {/* footer wrapper */}
                  </div>
                  {/* footer link */}
                </div>
                <div className="col-lg-3 col-md-5 col-sm-5">
                  <div className="footer-contact mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                    <div className="footer-title">
                      <h4 className="title">Contactanos</h4>
                    </div>
                    <ul className="contact">
                      <li>+54-911-22526382</li>
                      <li>info@abue.org</li>
                      <li>chris@chance.com.ar</li>
                    </ul>
                  </div>
                  {/* footer contact */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* footer widget */}
            <div className="footer-copyright">
              <div className="row">
                <div className="col-lg-12">
                  <div className="copyright d-sm-flex justify-content-between">
                    <div className="copyright-content">
                      <p className="text">Copyright DataXype 2021
                      </p>
                    </div>
                    {/* copyright content */}
                  </div>
                  {/* copyright */}
                </div>
              </div>
              {/* row */}
            </div>
            {/* footer copyright */}
          </div>
          {/* container */}
          <div id="particles-2" />
        </footer>
        <a href="#" className="back-to-top">
          <i className="lni-chevron-up" />
        </a>
       
      </div>

  </React.Fragment>
)}
</>
);
}
}
export default Landing;